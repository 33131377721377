.field {
    position: absolute;

    border: 1px solid var(--field-border-color);
}

.field { 
    width: calc(var(--field-radius) * 2);
    height: calc(var(--field-radius) * 2);
    border-radius: var(--field-radius);
}

:root {
    --field-radius: 12px;
}

@media (min-width: 800px) and (min-height: 800px) {
    :root {
        --field-radius: 16px;
    }
}

@media (min-width: 1000px) and (min-height: 1000px) {
    :root {
        --field-radius: 20px;
    }
}

@media (min-width: 1200px) and (min-height: 1200px) {
    :root {
        --field-radius: 24px;
    }
}

/* This is an attempt to add the impression of depth to the fields, but it didn't work out so well
.field--empty {
    box-shadow: inset 0 0 5px #0000009c;
}

.field:not(.field--empty) {
    box-shadow: 2px 2px 2px 2px #0000009c;
} */

.field--empty:nth-child(even) {
    background-color: var(--empty-field-even);
}

.field--empty:nth-child(odd) {
    background-color: var(--empty-field-odd);
}

.field--red {
    background-color: var(--red);
}

.field--blue {
    background-color: var(--blue);
}

.field--green {
    background-color: var(--green);
}

.field--black {
    background-color: var(--black);
}

.field--picked-up {
    box-shadow: 0 0 4px 4px var(--selected-yellow), inset 0 0 2px 2px var(--selected-yellow) !important;
    border-color: var(--selected-yellow);
}

.field-64, .field-65, .field-66, .field-67 {
    box-shadow: 0 0 6px 0.5px var(--red);}

.field-68, .field-69, .field-70, .field-71 {
    box-shadow: 0 0 6px 0.5px var(--blue);
}

.field-72, .field-73, .field-74, .field-75 {
    box-shadow: 0 0 6px 0.5px var(--black);
}

.field-76, .field-77, .field-78, .field-79 {
    box-shadow: 0 0 6px 0.5px var(--green);
}

.field-0 { top: calc(50% + 45% * 0 - var(--field-radius));	left: calc(50% - 45% * 1 - var(--field-radius)); }
.field-1 { top: calc(50% + 45% * 0.0980171403295606 - var(--field-radius));	left: calc(50% - 45% * 0.995184726672197 - var(--field-radius)); }
.field-2 { top: calc(50% + 45% * 0.195090322016128 - var(--field-radius));	left: calc(50% - 45% * 0.98078528040323 - var(--field-radius)); }
.field-3 { top: calc(50% + 45% * 0.290284677254462 - var(--field-radius));	left: calc(50% - 45% * 0.956940335732209 - var(--field-radius)); }
.field-4 { top: calc(50% + 45% * 0.38268343236509 - var(--field-radius));	left: calc(50% - 45% * 0.923879532511287 - var(--field-radius)); }
.field-5 { top: calc(50% + 45% * 0.471396736825998 - var(--field-radius));	left: calc(50% - 45% * 0.881921264348355 - var(--field-radius)); }
.field-6 { top: calc(50% + 45% * 0.555570233019602 - var(--field-radius));	left: calc(50% - 45% * 0.831469612302545 - var(--field-radius)); }
.field-7 { top: calc(50% + 45% * 0.634393284163645 - var(--field-radius));	left: calc(50% - 45% * 0.773010453362737 - var(--field-radius)); }
.field-8 { top: calc(50% + 45% * 0.707106781186547 - var(--field-radius));	left: calc(50% - 45% * 0.707106781186548 - var(--field-radius)); }
.field-9 { top: calc(50% + 45% * 0.773010453362737 - var(--field-radius));	left: calc(50% - 45% * 0.634393284163646 - var(--field-radius)); }
.field-10 { top: calc(50% + 45% * 0.831469612302545 - var(--field-radius));	left: calc(50% - 45% * 0.555570233019602 - var(--field-radius)); }
.field-11 { top: calc(50% + 45% * 0.881921264348355 - var(--field-radius));	left: calc(50% - 45% * 0.471396736825998 - var(--field-radius)); }
.field-12 { top: calc(50% + 45% * 0.923879532511287 - var(--field-radius));	left: calc(50% - 45% * 0.38268343236509 - var(--field-radius)); }
.field-13 { top: calc(50% + 45% * 0.956940335732209 - var(--field-radius));	left: calc(50% - 45% * 0.290284677254462 - var(--field-radius)); }
.field-14 { top: calc(50% + 45% * 0.98078528040323 - var(--field-radius));	left: calc(50% - 45% * 0.195090322016128 - var(--field-radius)); }
.field-15 { top: calc(50% + 45% * 0.995184726672197 - var(--field-radius));	left: calc(50% - 45% * 0.0980171403295605 - var(--field-radius)); }
.field-16 { top: calc(50% + 45% * 1 - var(--field-radius));	left: calc(50% - 45% * 6.1257422745431E-17 - var(--field-radius)); }
.field-17 { top: calc(50% + 45% * 0.995184726672197 - var(--field-radius));	left: calc(50% - 45% * -0.0980171403295604 - var(--field-radius)); }
.field-18 { top: calc(50% + 45% * 0.980785280403231 - var(--field-radius));	left: calc(50% - 45% * -0.195090322016128 - var(--field-radius)); }
.field-19 { top: calc(50% + 45% * 0.956940335732209 - var(--field-radius));	left: calc(50% - 45% * -0.290284677254462 - var(--field-radius)); }
.field-20 { top: calc(50% + 45% * 0.923879532511287 - var(--field-radius));	left: calc(50% - 45% * -0.38268343236509 - var(--field-radius)); }
.field-21 { top: calc(50% + 45% * 0.881921264348355 - var(--field-radius));	left: calc(50% - 45% * -0.471396736825997 - var(--field-radius)); }
.field-22 { top: calc(50% + 45% * 0.831469612302545 - var(--field-radius));	left: calc(50% - 45% * -0.555570233019602 - var(--field-radius)); }
.field-23 { top: calc(50% + 45% * 0.773010453362737 - var(--field-radius));	left: calc(50% - 45% * -0.634393284163645 - var(--field-radius)); }
.field-24 { top: calc(50% + 45% * 0.707106781186548 - var(--field-radius));	left: calc(50% - 45% * -0.707106781186547 - var(--field-radius)); }
.field-25 { top: calc(50% + 45% * 0.634393284163645 - var(--field-radius));	left: calc(50% - 45% * -0.773010453362737 - var(--field-radius)); }
.field-26 { top: calc(50% + 45% * 0.555570233019602 - var(--field-radius));	left: calc(50% - 45% * -0.831469612302545 - var(--field-radius)); }
.field-27 { top: calc(50% + 45% * 0.471396736825998 - var(--field-radius));	left: calc(50% - 45% * -0.881921264348355 - var(--field-radius)); }
.field-28 { top: calc(50% + 45% * 0.38268343236509 - var(--field-radius));	left: calc(50% - 45% * -0.923879532511287 - var(--field-radius)); }
.field-29 { top: calc(50% + 45% * 0.290284677254462 - var(--field-radius));	left: calc(50% - 45% * -0.956940335732209 - var(--field-radius)); }
.field-30 { top: calc(50% + 45% * 0.195090322016128 - var(--field-radius));	left: calc(50% - 45% * -0.98078528040323 - var(--field-radius)); }
.field-31 { top: calc(50% + 45% * 0.0980171403295608 - var(--field-radius));	left: calc(50% - 45% * -0.995184726672197 - var(--field-radius)); }
.field-32 { top: calc(50% + 45% * 1.22514845490862E-16 - var(--field-radius));	left: calc(50% - 45% * -1 - var(--field-radius)); }
.field-33 { top: calc(50% + 45% * -0.0980171403295606 - var(--field-radius));	left: calc(50% - 45% * -0.995184726672197 - var(--field-radius)); }
.field-34 { top: calc(50% + 45% * -0.195090322016128 - var(--field-radius));	left: calc(50% - 45% * -0.980785280403231 - var(--field-radius)); }
.field-35 { top: calc(50% + 45% * -0.290284677254462 - var(--field-radius));	left: calc(50% - 45% * -0.956940335732209 - var(--field-radius)); }
.field-36 { top: calc(50% + 45% * -0.382683432365089 - var(--field-radius));	left: calc(50% - 45% * -0.923879532511287 - var(--field-radius)); }
.field-37 { top: calc(50% + 45% * -0.471396736825998 - var(--field-radius));	left: calc(50% - 45% * -0.881921264348355 - var(--field-radius)); }
.field-38 { top: calc(50% + 45% * -0.555570233019602 - var(--field-radius));	left: calc(50% - 45% * -0.831469612302545 - var(--field-radius)); }
.field-39 { top: calc(50% + 45% * -0.634393284163645 - var(--field-radius));	left: calc(50% - 45% * -0.773010453362737 - var(--field-radius)); }
.field-40 { top: calc(50% + 45% * -0.707106781186547 - var(--field-radius));	left: calc(50% - 45% * -0.707106781186548 - var(--field-radius)); }
.field-41 { top: calc(50% + 45% * -0.773010453362737 - var(--field-radius));	left: calc(50% - 45% * -0.634393284163645 - var(--field-radius)); }
.field-42 { top: calc(50% + 45% * -0.831469612302545 - var(--field-radius));	left: calc(50% - 45% * -0.555570233019603 - var(--field-radius)); }
.field-43 { top: calc(50% + 45% * -0.881921264348355 - var(--field-radius));	left: calc(50% - 45% * -0.471396736825998 - var(--field-radius)); }
.field-44 { top: calc(50% + 45% * -0.923879532511287 - var(--field-radius));	left: calc(50% - 45% * -0.38268343236509 - var(--field-radius)); }
.field-45 { top: calc(50% + 45% * -0.956940335732209 - var(--field-radius));	left: calc(50% - 45% * -0.290284677254462 - var(--field-radius)); }
.field-46 { top: calc(50% + 45% * -0.98078528040323 - var(--field-radius));	left: calc(50% - 45% * -0.195090322016129 - var(--field-radius)); }
.field-47 { top: calc(50% + 45% * -0.995184726672197 - var(--field-radius));	left: calc(50% - 45% * -0.0980171403295605 - var(--field-radius)); }
.field-48 { top: calc(50% + 45% * -1 - var(--field-radius));	left: calc(50% - 45% * -1.83772268236293E-16 - var(--field-radius)); }
.field-49 { top: calc(50% + 45% * -0.995184726672197 - var(--field-radius));	left: calc(50% - 45% * 0.0980171403295601 - var(--field-radius)); }
.field-50 { top: calc(50% + 45% * -0.98078528040323 - var(--field-radius));	left: calc(50% - 45% * 0.195090322016128 - var(--field-radius)); }
.field-51 { top: calc(50% + 45% * -0.956940335732209 - var(--field-radius));	left: calc(50% - 45% * 0.290284677254462 - var(--field-radius)); }
.field-52 { top: calc(50% + 45% * -0.923879532511287 - var(--field-radius));	left: calc(50% - 45% * 0.38268343236509 - var(--field-radius)); }
.field-53 { top: calc(50% + 45% * -0.881921264348355 - var(--field-radius));	left: calc(50% - 45% * 0.471396736825998 - var(--field-radius)); }
.field-54 { top: calc(50% + 45% * -0.831469612302545 - var(--field-radius));	left: calc(50% - 45% * 0.555570233019602 - var(--field-radius)); }
.field-55 { top: calc(50% + 45% * -0.773010453362737 - var(--field-radius));	left: calc(50% - 45% * 0.634393284163646 - var(--field-radius)); }
.field-56 { top: calc(50% + 45% * -0.707106781186548 - var(--field-radius));	left: calc(50% - 45% * 0.707106781186547 - var(--field-radius)); }
.field-57 { top: calc(50% + 45% * -0.634393284163646 - var(--field-radius));	left: calc(50% - 45% * 0.773010453362737 - var(--field-radius)); }
.field-58 { top: calc(50% + 45% * -0.555570233019602 - var(--field-radius));	left: calc(50% - 45% * 0.831469612302545 - var(--field-radius)); }
.field-59 { top: calc(50% + 45% * -0.471396736825998 - var(--field-radius));	left: calc(50% - 45% * 0.881921264348355 - var(--field-radius)); }
.field-60 { top: calc(50% + 45% * -0.38268343236509 - var(--field-radius));	left: calc(50% - 45% * 0.923879532511287 - var(--field-radius)); }
.field-61 { top: calc(50% + 45% * -0.290284677254463 - var(--field-radius));	left: calc(50% - 45% * 0.956940335732209 - var(--field-radius)); }
.field-62 { top: calc(50% + 45% * -0.195090322016129 - var(--field-radius));	left: calc(50% - 45% * 0.98078528040323 - var(--field-radius)); }
.field-63 { top: calc(50% + 45% * -0.0980171403295605 - var(--field-radius));	left: calc(50% - 45% * 0.995184726672197 - var(--field-radius)); }

.field-64 { top: calc(50% - var(--field-radius));	left: 20%; }
.field-65 { top: calc(50% - var(--field-radius));	left: 16%; }
.field-66 { top: calc(50% - var(--field-radius));	left: 12%; }
.field-67 { top: calc(50% - var(--field-radius));	left: 8%; }

.field-68 { top: calc(50% - var(--field-radius));	right: 19%; }
.field-69 { top: calc(50% - var(--field-radius));	right: 15%; }
.field-70 { top: calc(50% - var(--field-radius));	right: 11%; }
.field-71 { top: calc(50% - var(--field-radius));	right: 7%; }

.field-72 { left: calc(50% - var(--field-radius));	top: 20%; }
.field-73 { left: calc(50% - var(--field-radius));	top: 16%; }
.field-74 { left: calc(50% - var(--field-radius));	top: 12%; }
.field-75 { left: calc(50% - var(--field-radius));	top: 8%; }

.field-76 { left: calc(50% - var(--field-radius));	bottom: 19%; }
.field-77 { left: calc(50% - var(--field-radius));	bottom: 15%; }
.field-78 { left: calc(50% - var(--field-radius));	bottom: 11%; }
.field-79 { left: calc(50% - var(--field-radius));	bottom: 7%; }