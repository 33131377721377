.house {
    position: absolute;
    border: 1px solid var(--field-border-color);

    background-color: var(--empty-field-even);
}

.house { 
    width: 80px;
    height: 80px;
    border-radius: 40px;
}

.house__marble {
    width: calc(var(--field-radius) * 2);
    height: calc(var(--field-radius) * 2);
    border-radius: var(--field-radius);
    margin: 1px;
}

.house__marble-container {
    height: 58px;
    width: 58px;
    margin: 11px;
}

@media (min-width: 800px) and (min-height: 800px) {
    .house { 
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }

    .house__marble {
        margin: 2px;
    }

    .house__marble-container {
        height: 79px;
        width: 79px;
        margin: 11px;
    }
}

@media (min-width: 1000px) and (min-height: 1000px) {
    .house { 
        width: 120px;
        height: 120px;
        border-radius: 60px;
    }

    .house__marble {
        margin: 2px;
    }

    .house__marble-container {
        height: 96px;
        width: 96px;
        margin: 12px;
    }
}

@media (min-width: 1200px) and (min-height: 1200px) {
    .house { 
        width: 140px;
        height: 140px;
        border-radius: 70px;
    }

    .house__marble {
        margin: 3px;
    }

    .house__marble-container {
        height: 114px;
        width: 114px;
        margin: 13px;
    }
}

.house--picked-up .house__marble:last-of-type {
    box-shadow: 0 0 4px 4px var(--selected-yellow);
}

.house--black {
    top: 16px;
    left: 16px
}

.house--blue {
    top: 16px;
    right:  16px
}

.house--green {
    bottom: 16px;
    right: 16px
}

.house--red {
    bottom: 16px;
    left: 16px
}

.house__marble-container {
    display: flex;
    flex-wrap: wrap;
}

.house__marble {
    border: 1px solid var(--field-border-color);
}

.house--red .house__marble {
    background-color: var(--red);
}

.house--blue .house__marble {
    background-color: var(--blue);
}

.house--green .house__marble {
    background-color: var(--green);
}

.house--black .house__marble {
    background-color: var(--black);
}