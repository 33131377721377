.App {
  text-align: center;
}

:root {
  --wood-background: #d3b17d;
  --text-black: #192823;


  /* Marble-related colors */
  --red: #c70039;
  --green: #0b8457;
  --blue: #2056b4;
  --black: #192823;

  --selected-yellow: #eac100;
  
  /* Board-related colors */
  --empty-field-even: #eae9e9; /* previously `lightslategrey` */
  --empty-field-odd: #c9cbd1; /* previously `grey` */
  --field-border-color: rgb(73, 73, 73);

  color: var(--text-black);
}

button {
  background-color: white;
  border-color: var(--text-black);
  border-radius: 4px;

  color: var(--text-black);

  font-size: 24px;
  height: 48px;
  padding: 0 12px;
}

a {
  color: var(--text-black);
}

