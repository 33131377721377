.footer {
    padding: 24px;
}

.footer__content {
    display: flex;
}

@media (max-width: 450px) {
    .footer__content {
        flex-direction: column;
    }
}

.footer__link {
    display: flex;
    margin: 16px;
    align-items: center;
}

.footer__github-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}