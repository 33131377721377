.Board {
    position: relative;

    width: 100vw;
    height: 100vw;
    max-width: 100vh;
    max-height: 100vh;
    min-width: 600px;
    min-height: 600px;

    margin: auto;
}

.Board__container {
    background-color: var(--wood-background);
}

.Board__game-link {
    font-size: 24px;
    padding: 12px;
}